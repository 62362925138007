var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"title"},[_vm._v("Facture")]),_c('h3',{attrs:{"id":"manuelle"}},[_vm._v("Creation Facture manuelle")]),_c('h4',[_vm._v(" Si vous souhaitez créer une facture manuellement, veuillez suivre les étapes ci-dessous : ")]),_vm._m(0),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1682601231_CréationFactureManuelle.mp4`,"controls":""}})]),_c('h3',{attrs:{"id":"acompte"}},[_vm._v("Générer Facture acompte depuis devis")]),_c('h4',[_vm._v(" Si vous souhaitez générer une facture d'acompte à partir d'un devis, veuillez suivre les étapes ci-dessous : ")]),_vm._m(1),_c('h3',{attrs:{"id":"devis"}},[_vm._v("Générer Facture depuis devis")]),_c('h4',[_vm._v(" Si vous souhaitez générer une facture à partir d'un devis, veuillez suivre les étapes ci-dessous : ")]),_vm._m(2),_c('h3',{attrs:{"id":"consultation_facture"}},[_vm._v("Consultation des factures")]),_c('h4',[_vm._v(" Pour consulter vos factures, vous pouvez suivre les étapes ci-dessous : ")]),_vm._m(3),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1682601075_ConsultationFactures.mp4`,"controls":""}})]),_c('h3',{attrs:{"id":"consultation_acompte"}},[_vm._v("Consultation des factures acomptes")]),_c('h4',[_vm._v(" Pour consulter vos factures, vous pouvez suivre les étapes ci-dessous : ")]),_vm._m(4),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          `public/how-it-works/videos/1682601166_ConsultationFacturesAcompte.mp4`,"controls":""}})]),_c('div',{staticClass:"bulle"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"33","height":"33","viewBox":"0 0 33 33"}},[_c('g',{attrs:{"id":"Icon_feather-info","data-name":"Icon feather-info","transform":"translate(-1.5 -1.5)"}},[_c('path',{attrs:{"id":"Tracé_78064","data-name":"Tracé 78064","d":"M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78065","data-name":"Tracé 78065","d":"M18,24V18","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78066","data-name":"Tracé 78066","d":"M18,12h0","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}})])]),_vm._v(" Nous espérons que ces instructions vous ont été utiles. Si vous avez des questions ou des problèmes, n'hésitez pas à nous contacter via notre email de contact. Merci de nous faire confiance et à bientôt sur Batigo ! ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez sur la page de gestion des factures.")]),_c('li',[_vm._v("Cliquez sur le bouton \"Nouvelle facture\".")]),_c('li',[_vm._v(" Remplissez le formulaire de création de facture en saisissant les informations nécessaires. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Générer facture\". Votre facture est maintenant créée. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Allez dans le menu principal et cliquez sur \"Devis\".")]),_c('li',[_vm._v(" Sélectionnez le devis pour lequel vous souhaitez générer une facture d'acompte. ")]),_c('li',[_vm._v("Cliquez sur le bouton \"Générer facture\".")]),_c('li',[_vm._v(" Dans le formulaire de création de facture, choisissez le type de facture \"Acompte\". ")]),_c('li',[_vm._v(" Indiquez le montant de l'acompte souhaité en pourcentage ou en valeur. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Confirmer\" pour générer la facture d'acompte. ")]),_c('li',[_vm._v(" Vous pouvez ensuite envoyer la facture d'acompte par email au client en utilisant l'option d'envoi par email disponible sur la page de la facture générée. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la page de gestion des devis.")]),_c('li',[_vm._v(" Sélectionnez le devis pour lequel vous souhaitez générer une facture. ")]),_c('li',[_vm._v("Cliquez sur le bouton \"Générer une facture\".")]),_c('li',[_vm._v(" Choisissez le type de la facture soit \"Fin Travaux\" ou \"bien acompte\". ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"confirmer\". Votre facture est maintenant créée. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la section \"Factures\" dans le menu principal.")]),_c('li',[_vm._v("Accédez à la Liste des acomptes.")]),_c('li',[_vm._v("Vous pouvez maintenant voir la liste de toutes les acomptes.")]),_c('li',[_vm._v(" Cliquez sur la acompte que vous souhaitez consulter pour voir les détails. ")]),_c('li',[_vm._v(" Si vous le souhaitez, vous pouvez également télécharger l'acompte en format PDF en cliquant sur le bouton \"Télécharger\". ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la section \"Factures\" dans le menu principal.")]),_c('li',[_vm._v("Accédez à la Liste des acomptes.")]),_c('li',[_vm._v("Vous pouvez maintenant voir la liste de toutes les acomptes.")]),_c('li',[_vm._v(" Cliquez sur la acompte que vous souhaitez consulter pour voir les détails. ")]),_c('li',[_vm._v(" Si vous le souhaitez, vous pouvez également télécharger l'acompte en format PDF en cliquant sur le bouton \"Télécharger\". ")])])
}]

export { render, staticRenderFns }